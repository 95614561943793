<template>
  <b-card>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <!-- form -->
      <b-form @submit.stop.prevent="handleSubmit(createCompanyUser)">
        <b-row>
          <!-- username -->
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              :name="$t('Username')"
              :rules="{ required: true }"
            >
              <b-form-group
                label-for="username"
                :state="getValidationState(validationContext)"
                :label="$t('Username')"
              >
                <b-form-input
                  id="username"
                  v-model="userInfo.data.username"
                />
              </b-form-group>
              <b-form-invalid-feedback id="input-22-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <!--/ username -->

          <!-- contactFirstName -->
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              rules="required"
              :name="$t('First Name')"
            >
              <b-form-group
                label-for="FirstName"
                :state="getValidationState(validationContext)"
                :label="$t('First Name')"
              >
                <b-form-input
                  id="FirstName"
                  v-model="userInfo.data.contactFirstName"
                />
              </b-form-group>
              <b-form-invalid-feedback id="input-22-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <!--/ contactFirstName -->

          <!-- Last Name -->
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              :name="$t('Username')"
              :rules="{ required: true }"
            >
              <b-form-group
                label-for="LastName"
                :state="getValidationState(validationContext)"
                :label="$t('Last Name')"
              >
                <b-form-input
                  id="LastName"
                  v-model="userInfo.data.contactLastName"
                />
              </b-form-group>
              <b-form-invalid-feedback id="input-22-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <!--/ Last Name -->

          <!-- Email -->
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              :name="$t('Email')"
              rules="required|email"
            >
              <b-form-group
                label-for="Email"
                :state="getValidationState(validationContext)"
                :label="$t('Email')"
              >
                <b-form-input
                  id="Email"
                  v-model="userInfo.data.contactEmail"
                />
              </b-form-group>
              <b-form-invalid-feedback id="input-22-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider></b-col>
          <!--/ Email -->

          <!-- PhoneNumber -->
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              :name="$t('Username')"
              :rules="{ required: true }"
            >
              <b-form-group
                label-for="phoneNumber"
                :state="getValidationState(validationContext)"
                :label="$t('The mobile number of the company representative')"
              >
                <b-form-input
                  id="phoneNumber"
                  v-model="userInfo.data.mobileNumber"
                />
              </b-form-group>
              <b-form-invalid-feedback id="input-22-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider></b-col>
        <!--/ PhoneNumber -->
        </b-row>

        <b-row>
          <b-col
            md="4"
            sm="4"
            class="mb-2"
          >
            <b-button
              class="text-capitalize mt-1"
              :ripple="'rgba(113, 102, 240, 0.15)'"
              :disabled="isDisabled()"
              type="submit"
              variant="primary"
            >
              {{ $t('Add Company Admin') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
// eslint-disable-next-line import/no-extraneous-dependencies

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: '',
      userInfo: {
        partnerType: 'company',
        data: {
          companyName: '',
          contactEmail: '',
          contactFirstName: '',
          contactLastName: '',
          companyCommerceNumber: '',
          username: '',
          taxId: '',
          mobileNumber: '',
          isActive: true,
          partnerAddress: {
            line1: '',
            city: '',
            zip: '',
            line2: '',
          },
        },
      },
    }
  },
  mounted() {
    this.companyInfo = JSON.parse(localStorage.getItem('userInfo')).partnerInfo.data
    console.log('companyInfo', this.companyInfo)
    this.userInfo.data.companyName = this.companyInfo.companyName
    this.userInfo.data.companyCommerceNumber = this.companyInfo.companyCommerceNumber
    this.userInfo.data.taxId = this.companyInfo.taxId
    this.userInfo.data.partnerAddress = this.companyInfo.partnerAddress
  },
  methods: {
    createCompanyUser() {
      useJwt.post('/partner/api/domain/partners/register', {
        partnerInfo: this.userInfo,
      })
        .then(({ data }) => {
          console.log(data)
          this.$swal({
            icon: 'success',
            title: this.$t('Success'),
            text: this.$t('The Partner has been created successfully'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$router.push('/')
        })
        .catch(err => {
          console.log(err)
          this.$swal({
            icon: 'error',
            title: this.$t('Oops'),
            text: this.$t('Something went wrong'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .finally(() => { this.isLoading = false })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    isDisabled() {
      const vs = Object.keys(this.userInfo.data).map(key => {
        console.log(`key: ${key} value: ${this.userInfo[key]}`)
        if (!this.userInfo.data[key] || this.userInfo.data[key] === '') {
          return false
        }
        return true
      })
      return vs.includes(false)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
